<template>
	<div id="booking-form" :class="{'disabled': lockform === true}">
		<form @submit.prevent="bookExperience" novalidate>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="name">
							<div class="form-field input">
								<input v-model="model.name" type="text" class="field" placeholder="Name" required>
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="practice_name">
							<div class="form-field input">
								<input v-model="model.practice_name" type="text" class="field" placeholder="Practice Name" required>
							</div>
						</label>
					</div>
				</div>
			</div>
			<h3>Practice Address</h3>
			<p>Please ensure this is the address for the main practice you work at</p>
			<br>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="address_line_1">
							<div class="form-field input">
								<input v-model="model.address_line_1" type="text" class="field" placeholder="Address Line 1">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="address_line_2">
							<div class="form-field input">
								<input v-model="model.address_line_2" type="text" class="field" placeholder="Address Line 2">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="city">
							<div class="form-field input">
								<input v-model="model.city" type="text" class="field" placeholder="City">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="county">
							<div class="form-field input">
								<input v-model="model.county" type="text" class="field" placeholder="County">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="postcode">
							<div class="form-field input">
								<input v-model="model.postcode" type="text" class="field" placeholder="Postcode">
							</div>
						</label>
					</div>
				</div>
			</div>
			<br>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="email">
							<div class="form-field input">
								<input v-model="model.email" type="email" class="field" placeholder="Email" maxlength="64" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="mobile_number">
							<div class="form-field input">
								<input v-model="model.mobile_number" type="tel" class="field" placeholder="Mobile Number" required>
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="dietary_requirements">
							<div class="form-field input">
								<input v-model="model.dietary_requirements" type="text" class="field" placeholder="Dietary Requirements">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="accessibility_requirements">
							<div class="form-field input">
								<input v-model="model.accessibility_requirements" type="text" class="field" placeholder="Accessibility Requirements">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="referrer_name">
							<div class="form-field input">
								<input v-model="model.referrer_name" type="text" class="field" placeholder="Referrer Name">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row" v-if="list.experiences.length">
				<div class="col s12 m12 l12">
					<div class="form-block">
						<fieldset class="form-label" data-field="experience_date">
							<span class="label">Select your Experience</span>
							<p v-if="requiresHoldingFee">Holding fee required to secure seat. Will be refunded after the experience.</p>
							<div class="ui-radio" v-for="experience in list.experiences" v-if="!experience.sold_out">
								<label @click="selectExperience(experience)">
									<input type="radio" v-model="model.experience_date" :value="experience.date" />
									<span class="radio"></span>
									<span v-html="`${$moment(experience.date, 'DD/MM/YYYY').format('dddd Do MMMM')} - ${experience.location} ${experience.cost ? '- £' + experience.cost.toFixed(2) : ''}`"></span>
								</label>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<div class="col-row" v-if="getSelectedExperience && showVoucher">
				<div class="col s12 m8 l8">
					<div class="form-block">
						<label class="form-label" data-field="voucher">
							<div class="form-field input">
								<input v-model="model.voucher" type="text" class="field" placeholder="Voucher">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m4 l4">
					<div class="submit">
						<button type="button" class="ui-btn ui-btn-block ui-btn-rounded" @click="redeemVoucher" :disabled="isApplyingVoucher">Redeem Voucher</button>
					</div>
				</div>
			</div>
			<div class="payment v-middle" v-if="price">
				<div class="amount v-content">
					<p>Total <span>&pound;{{price.toFixed(2)}}</span></p>
				</div>
			</div>
			<div class="col-row" v-if="price">
				<div class="col s12 m12 l12">
					<div class="form-block">
						<label class="form-label" data-field="card_number">
							<div class="form-field input">
								<cleave type="tel" name="cardnumber" class="field" v-model="model.card.number" :options="cardOptions.creditCard" placeholder="0000 0000 0000 0000"></cleave>
							</div>
						</label>
					</div>
				</div>
				<div class="col s6 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="card_expiry">
							<div class="form-field input">
								<cleave type="tel" name="cardexpiry" v-model="model.card.expiry" class="field" :raw="false" :options="cardOptions.expiry" placeholder="MM/YYYY"></cleave>
							</div>
						</label>
					</div>
				</div>
				<div class="col s6 m6 l6">
					<div class="form-block">
						<label class="form-label" data-field="card_cvc">
							<div class="form-field input">
								<input type="tel" name="cardcvc" class="field" v-model="model.card.cvc" minlength="3" maxlength="4" placeholder="****">
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="submit">
				<button type="submit" class="ui-btn ui-btn-rounded" v-text="sending ? 'Submitting...' : 'Submit'" :disabled="sending"></button>
			</div>
		</form>
	</div>
</template>
<script>
	import helper from '../../helpers';
	import {bookingApi} from '../../api';
	import stripe from '../../stripe';
	export default {
		props: {
			experiences: {
				required: true
			},
			event: {
				required: false
			},
		},
		data () {
			return {
				price: 0,
				isApplyingVoucher: false,
				lockform: false,
				sending: false,
				list: {
					experiences: '',
				},
				model: {},
				cardOptions: {
					creditCard: {
						creditCard: true,
						delimiter: ' ',
					},
					expiry: {
						date: true,
						datePattern: ['m', 'Y']
					}
				},
			}
		},
		methods: {
			selectExperience(experience) {
				let self = this;
				self.price = experience.cost;
				// clear voucher
				self.model.voucher = "";
			},
			redeemVoucher() {
				let self = this;
				self.isApplyingVoucher = true;
				bookingApi.getVoucher(self.model.voucher).then(response => {
					self.isApplyingVoucher = false;
					let voucherData = response.data;
					if(voucherData.valid) {
						alert(`Voucher ${self.model.voucher} has been applied`);
						if(voucherData.percent_off) {
							// apply new cost
							let discount = self.getSelectedExperience.cost * voucherData.percent_off / 100;
							self.price = self.getSelectedExperience.cost - discount;
						}
					} else {
						alert(`Voucher ${self.model.voucher} is no longer valid`);
					}
				}, (error) => {
					self.isApplyingVoucher = false;
					let errors = error.response.data;
					console.log(errors);
					if(errors.error && errors.code) {
						alert(errors.message);
					} else {
						alert("There was a problem applying your voucher");
					}
				});
			},
			processApplication() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				bookingApi.bookExperience(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						window.location.href = self.$app.site_url + '/thank-you-seat-confirmation/';
					}
				}, (error) => {
					self.sending = false;
					let errors = error.response.data;
					console.log(errors);
					if(errors.errors) {
						Object.entries(errors.errors).forEach((value, index) => {
							if(Array.isArray(value[1])) {
								if(value[1].length) {
									value[1].forEach((item, index) => {
										Object.entries(item).forEach((value) => {
											$('.delegate-' + index).find('[data-field="' + value[0] + '"]').append('<span class="error">' + value[1] + '</span>');
										});
									});
								}
							} else if (typeof value[1] === 'object') {
								Object.entries(value[1]).forEach((item) => {
									Object.entries(item[1]).forEach((key_field) => {
										$('.delegate-' + item[0]).find('[data-field="' + key_field[0] + '"]').append('<span class="error">' + key_field[1] + '</span>');
									});
								});
							} else {
								$('[data-field="' + value[0] + '"]').append('<span class="error">' + value[1] + '</span>');
							}
						});
					} else {
						if(errors.error && errors.code) {
							if(errors.code === 'payment_requires_action') {
								self.lockform = true;
								self.sending = true;
								// prompt 3Dsecure
								stripe.confirmCardPayment(errors.order.pi_client_secret, {
									payment_method: errors.order.pi_payment_method
								}).then((result) => {
									if(result.error) {
										alert(result.error.message);
										self.lockform = false;
										self.sending = false;
									}
									// rebook ticket and process
									if(result.paymentIntent.status === 'succeeded') {
										self.model.id = result.paymentIntent.id;
										self.processApplication();
									}
								});
							} else {
								alert(errors.message);
							}
						} else {
							alert("There was a problem placing your order");
						}
					}
				});
			},
			bookExperience() {
				let self = this;
				self.processApplication();
			},
			initModel() {
				let self = this;
				self.model = {
					id: "",
					name: "",
					email: "",
					experience_date: "",
					mobile_number: "",
					practice_name: "",
					address_line_1: "",
					address_line_2: "",
					city: "",
					county: "",
					postcode: "",
					dietary_requirements: "",
					accessibility_requirements: "",
					voucher: "",
					card: {
						number: "",
						expiry: "",
						cvc: ""
					},
					utm_source: helper.$_GET('utm_source') || "",
					utm_medium: helper.$_GET('utm_medium') || "",
					utm_campaign: helper.$_GET('utm_campaign') || "",
					utm_term: helper.$_GET('utm_term') || "",
					utm_content: helper.$_GET('utm_content') || "",
					event: helper.$_GET('event') || self.event,
					referrer_name: "",
				}
				self.list.experiences = JSON.parse(self.experiences);
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		computed: {
			showVoucher() {
				let events = ['nordics', 'nordics-booking-ortho'];
				return events.includes(this.model.event) ? false : true;
			},
			requiresHoldingFee() {
				let events = ['premium', 'nordics', 'nordics-booking-ortho'];
				return events.includes(this.model.event) ? false : true;
			},
			getSelectedExperience() {
				let self = this;
				return self.$_.find(self.list.experiences, { 'date': self.model.experience_date });
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>