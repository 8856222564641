var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "login-form" } }, [
    _c("div", { staticClass: "modal" }, [
      _c("div", { staticClass: "modal-area" }, [
        _c("h6", { staticClass: "heading-line center" }, [_vm._v("Login")]),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { autocomplete: "on" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.auth.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-block" }, [
              _c("label", { staticClass: "form-label" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Username or email address"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.user,
                        expression: "user.user",
                      },
                    ],
                    staticClass: "field",
                    attrs: { type: "text" },
                    domProps: { value: _vm.user.user },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "user", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-block" }, [
              _c("label", { staticClass: "form-label" }, [
                _c("span", { staticClass: "label" }, [_vm._v("Password")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-field input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password,
                        expression: "user.password",
                      },
                    ],
                    staticClass: "field",
                    attrs: { type: "password" },
                    domProps: { value: _vm.user.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.user, "password", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-block" }, [
              _c("div", { staticClass: "ui-checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.remember,
                        expression: "user.remember",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.user.remember)
                        ? _vm._i(_vm.user.remember, null) > -1
                        : _vm.user.remember,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.user.remember,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.user, "remember", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.user,
                                "remember",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.user, "remember", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Remember me")]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "login" }, [
              _c(
                "button",
                {
                  staticClass: "ui-btn ui-btn-block",
                  attrs: { type: "submit", disabled: _vm.sending },
                  domProps: {
                    textContent: _vm._s(
                      _vm.sending ? "Logging you in..." : "Login"
                    ),
                  },
                },
                [_vm._v("Login")]
              ),
            ]),
            _vm._v(" "),
            _vm.message
              ? _c("div", { staticClass: "messages ui-text-center" }, [
                  _c("p", { domProps: { textContent: _vm._s(_vm.message) } }),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }