var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.resetPassword.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-block" }, [
          _c("label", { staticClass: "form-label" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v("Enter New Password"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-field input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "field",
                attrs: {
                  type: "password",
                  placeholder: "Password",
                  required: "",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "button",
            {
              staticClass: "ui-btn ui-btn-block",
              attrs: {
                type: "submit",
                disabled: _vm.sending || !_vm.password.length,
              },
              domProps: {
                textContent: _vm._s(
                  _vm.sending ? "Setting Your Password..." : "Reset Password"
                ),
              },
            },
            [_vm._v("Reset Password")]
          ),
        ]),
        _vm._v(" "),
        _vm.message
          ? _c("div", { staticClass: "messages ui-text-center" }, [
              _c("p", { domProps: { textContent: _vm._s(_vm.message) } }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }